
// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonButtons, IonBackButton, } from '@ionic/vue';
import LoginForm from '@/components/auth/LoginForm.vue';

export default {
  name: 'LoginPage',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonGrid, IonButtons, IonBackButton,
                LoginForm, },
}
